import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  TRANSLOCO_CONFIG,
  Translation,
  TranslocoLoader,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import environment from '../../environments/environment.json';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  imports: [
    TranslocoMessageFormatModule.init({
      locales: 'pt-BR',
    }),
  ],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['pt-BR'],
        defaultLang: 'pt-BR',
        prodMode: environment.production,
        flatten: {
          aot: environment.production,
        }
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
