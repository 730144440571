import { Inject, Injectable } from '@angular/core';
import { LeadFile } from '@lead-hunter/data-access';

@Injectable({
  providedIn: 'root',
})
export class AnalysisService {
  constructor(
    @Inject('appStorage')
    private appStorage: string
  ) {}

  public setUploadedTags(tags: string[], file: string) {
    const leadFile: LeadFile = JSON.parse(file);
    const payload = {
      ...leadFile,
      tags,
    };
    localStorage.setItem(`${this.appStorage}.upFile`, JSON.stringify(payload));
  }

  public setUploadedFile(file: LeadFile | Array<string>) {
    localStorage.setItem(`${this.appStorage}.upFile`, JSON.stringify(file));
  }

  public getUploadedFile() {
    return localStorage.getItem(`${this.appStorage}.upFile`);
  }
}
