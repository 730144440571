import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { INTERCEPT_ALTERNATIVE_PREFIX, INTERCEPT_PREFIX } from '../consts/consts';
import { JsonApiService } from '../services/json-api.service';

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  private config: any = JSON.parse(localStorage.getItem('httpConf'));

  constructor(private apiService: JsonApiService) {
    this.apiService
      .fetch('/environments/environment.json')
      .subscribe((config) => {
        this.config = localStorage.setItem(
          'httpConf',
          JSON.stringify({ ...config })
        );
      });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.config = JSON.parse(localStorage.getItem('httpConf'));
    if (
      this.config &&
      request.headers.get(INTERCEPT_PREFIX) &&
      !request.url.startsWith('./')
    ) {
      request = request.clone({
        url: this.config.api + request.url,
        headers: this.removeHeaderTag(request),
      });
    }

    if (
      this.config &&
      request.headers.get(INTERCEPT_ALTERNATIVE_PREFIX) &&
      !request.url.startsWith('./')
    ) {
      request = request.clone({
        url: this.config[request.headers.get(INTERCEPT_ALTERNATIVE_PREFIX)] + request.url,
        headers: this.removeHeaderTag(request),
      });
    }
    return next.handle(request);
  }

  private removeHeaderTag(request: HttpRequest<any>): HttpHeaders {
    return request.headers.delete(INTERCEPT_PREFIX) || request.headers.delete(INTERCEPT_ALTERNATIVE_PREFIX);
  }
}
