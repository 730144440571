import { Component } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'lead-root',
  template: `<router-outlet>
    <ngx-spinner color="#00B1AB"></ngx-spinner>
  </router-outlet>`,
  styles: [''],
})
export class AppComponent {
  title = 'lead-hunter';
  constructor(private breadcrumbService: BreadcrumbService){}
}
