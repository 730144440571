import { Injectable } from '@angular/core';
import { apiStatus, appStatus, getStatus } from './status.enum';
import { countsResponse } from '../batch';

@Injectable({
  providedIn: 'root',
})
export class StatusService {

  public getStatus(countsResponse: countsResponse, item: string) {
    const status = getStatus[apiStatus[item]];
    if(status === appStatus.PENDING) return status;
    if(!countsResponse || countsResponse.companiesExtractionCount === 0) return appStatus.EMPTY;
    return status;
  }

}
