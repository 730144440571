import { User } from './user.model';
import { HttpService } from '../_http-settings/services';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpService,
    @Inject('appStorage')
    private appStorage: string
  ) {}

  public sendUser(): Observable<User> {
    const setUser = (data: any) => this.setUser(data);
    return this.http.get<User>(`/users/me`).pipe(tap(setUser));
  }

  public setUser(data): void {
    localStorage.setItem(`${this.appStorage}.user`, JSON.stringify(data));
  }

  public getUser(): User {
    return JSON.parse(localStorage.getItem(`${this.appStorage}.user`));
  }

  public removeUserFromStorage() {
    localStorage.removeItem(`${this.appStorage}.user`);
  }
}
