import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppStoragePrefixToken } from './consts/tokens';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiPrefixInterceptor, AuthInterceptor } from './interceptors';
import { HttpService, JsonApiService } from './services';

export interface ISettings {
  apiBaseUrl?: string;
  appStoragePrefix?: string;
  disableErrorHandler?: boolean;
}

@NgModule({
  imports: [CommonModule],
})
export class HttpSettingsModule {
  static forRoot(
    config: ISettings = {}
  ): ModuleWithProviders<HttpSettingsModule> {
    return {
      ngModule: HttpSettingsModule,
      providers: [
        {
          provide: AppStoragePrefixToken,
          useValue: config.appStoragePrefix || '',
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiPrefixInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        HttpService,
        JsonApiService,
      ],
    };
  }
}
