import { tap } from 'rxjs/operators';
import { INTERCEPT_AUTH } from './../consts/consts';
import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppStoragePrefixToken } from '../consts/tokens';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly STORAGE_KEY = 'token';

  constructor(@Inject(AppStoragePrefixToken) private storagePrefix: string) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.getToken();

    if (request.headers.get(INTERCEPT_AUTH) && token) {
      request = request.clone({
        headers: this.removeHeaderTag(request),
        setHeaders: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      });

      return next.handle(request).pipe(
        tap(
          (event: HttpEvent<any>) => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.clearToken();
                window.location.reload();
              }
            }
          }
        )
      );
    }

    return next.handle(request);
  }

  private getToken() {
    return localStorage.getItem(`${this.storagePrefix}.${this.STORAGE_KEY}`);
  }

  private clearToken() {
    return localStorage.removeItem(`${this.storagePrefix}.${this.STORAGE_KEY}`);
  }

  private removeHeaderTag(request: HttpRequest<any>): HttpHeaders {
    return request.headers.delete(INTERCEPT_AUTH);
  }
}
