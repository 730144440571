import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import environment from '../environments/environment.json';
import { HttpSettingsModule } from '@lead-hunter/data-access';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMaskModule } from 'ngx-mask';
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpSettingsModule.forRoot({
      apiBaseUrl: environment.api,
      appStoragePrefix: environment.storage,
    }),
    TranslocoRootModule,
    HttpClientModule,
    AppRoutingModule,
    NgxMaskModule.forRoot({}),
    NgxSpinnerModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: 'appStorage',
      useValue: environment.storage,
    },
    { provide: LOCALE_ID, useValue: 'pt' },
  ],
})
export class AppModule {}

registerLocaleData(localePt);
