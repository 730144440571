import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class JsonApiService {
  private http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }
  public fetch(url): Observable<any> {
    return this.http.get(this.getBaseUrl() + url).pipe(
      map(this.extractData),
      catchError((err) => this.handleError(err, url))
    );
  }

  public getBaseUrl() {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      '/'
    );
  }

  public extractData(res) {
    return res ? res : {};
  }

  public handleError(error: any, url: string) {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(url + ' => ' + errMsg);
    return throwError(errMsg);
  }
}
