export const SizeMock = [
    {
        label: 'ME',
        value: 'ME'
    },
    {
        label: 'DEMAIS',
        value: 'DEMAIS'
    },
    {
        label: 'EPP',
        value: 'EPP'
    },
    /*
    {
        label: 'EMPRESA DE PEQUENO PORTE',
        value: 'EMPRESA DE PEQUENO PORTE'
    },
    {
        label: 'MICRO EMPRESA',
        value: 'MICRO EMPRESA'
    },
    {
        label: 'MICRO',
        value: 'MICRO'
    },
    {
        label: 'EMPRESA PEQUENO PORTE',
        value: 'EMPRESA PEQUENO PORTE'
    }
    */
]
