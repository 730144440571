export enum apiStatus {
    DONE = 'DONE',
    CREATED = 'CREATED',
    PENDING = 'PENDING',
    ERROR_COUNTS = 'ERROR_COUNTS',
    ERROR_MAX_TIME = 'ERROR_MAX_TIME',
    ERROR_CUSTOM_FILTER_INVALID = 'ERROR_CUSTOM_FILTER_INVALID'
}

export enum appStatus {
    DONE,
    EMPTY,
    PENDING,
    ERROR
}

export const getStatus  = {
    [apiStatus.DONE]: appStatus.DONE,
    [apiStatus.CREATED]: appStatus.PENDING,
    [apiStatus.PENDING]: appStatus.PENDING,
    [apiStatus.ERROR_COUNTS]: appStatus.ERROR,
    [apiStatus.ERROR_MAX_TIME]: appStatus.ERROR,
    [apiStatus.ERROR_CUSTOM_FILTER_INVALID]: appStatus.EMPTY,
}