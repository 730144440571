import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  MappedState,
  ApiEstadosIBGE,
} from './address.model';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from '../_http-settings';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private readonly HEADERS = {
    'X-Disable-Api-Prefix': 'true',
  };

  constructor(private http: HttpService) {}


  getStates(): Observable<MappedState[]> {
    const headers = new HttpHeaders(this.HEADERS);

    const apiIBGE =
      'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome';

    return this.http
      .disableApiPrefix()
      .get<ApiEstadosIBGE[]>(apiIBGE, { headers })

      .pipe(
        map((response: ApiEstadosIBGE[]) => {
          return response.map((resp: ApiEstadosIBGE) => ({
            value: resp.sigla,
            label: resp.sigla,
          }));
        })
      );
  }
}
