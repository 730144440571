import { LeadFile } from '../interface';

export function readFile(
  data: File | Blob
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(data);
  });
}

export async function exportToLeadFile(filename: string, rows: string[]): Promise<LeadFile> {
  if (!rows || !rows.length) return;
  const separator = ',';
  const csvContent = rows.join(`${separator}\n`).split(separator);
  const blob = new Blob(csvContent, { type: 'text/csv;charset=utf-8;' });
  const file64 = await readFile(blob);
  const file: LeadFile = {
    extension: blob.type,
    file: <string>file64,
    name: filename,
    size: blob.size,
    date: Date.now(),
  };
  return file;
}
