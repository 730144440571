import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Login } from './auth.model';
import { AuthMock } from './auth.mock';
import { UserService } from '../user';
import { HttpService } from '../_http-settings/services';
import { tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _jwtHelper = new JwtHelperService();
  readonly MOCK = new AuthMock();

  constructor(
    private http: HttpService,
    private userService: UserService,
    @Inject('appStorage')
    private appStorage: string /* pega o appStorage lançado no app.module da aplicação */
  ) {}

  public authenticate(data: Login): Observable<string> {
    const setToken = (data: any) => this.setToken(data.access_token);
    return this.http.post<string>(`/auth/signin`, data).pipe(tap(setToken));
  }

  public resetPassword(data: Login): Observable<Login> {
    return this.http.post<Login>('/users/email', data);
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    if(!token) return false;
    return this.tokenIsValid(token);
  }

  public setToken(token: string) {
    localStorage.setItem(`${this.appStorage}.token`, token);
  }

  public getToken() {
    return localStorage.getItem(`${this.appStorage}.token`);
  }

  public logout() {
    localStorage.removeItem(`${this.appStorage}.token`);
    this.userService.removeUserFromStorage();
  }

  private tokenIsValid(token): boolean {
    const expiryTime = this._jwtHelper.getTokenExpirationDate(token);
    const now = new Date().getTime();
    return expiryTime.getTime() - now > 0;
  }
}
